<template lang="pug">
form-input-container(
  :label="label"
  :message="message"
  :message-type="messageType"
)
  .base-input(:class="disabled && '_disabled'" @click="costFocus")
    span.price_title(v-if="prefix") {{ prefix }}
    input(
      ref="costInput"
      :disabled="disabled"
      :value="innerCost"
      :placeholder="placeholder"
      @input="onInputCost($event.target.value)"
      @blur="$emit('blur:modelValue', checkLimit(toNumber(innerCost)))"
    )
</template>

<script lang="ts">
import type { PropType } from "vue";
import { toRefs, watch, ref, defineComponent } from "vue";

import FormInputContainer from "@/components/ui/form/container/FormInputContainer.vue";

export default defineComponent({
  name: "CostInput",
  components: {
    FormInputContainer,
  },
  props: {
    prefix: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    messageType: {
      type: String as PropType<'error'|'info'>,
      default: 'info',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: undefined,
    },
    max: {
      type: Number,
      default: undefined,
    },
  },
  emits: [
    'blur:modelValue',
    'update:modelValue',
  ],
  setup(props, { emit }) {

    const { modelValue } = toRefs(props);
    const formatter = new Intl.NumberFormat("ru-RU");

    const innerCost = ref(formatCost(modelValue.value));
    watch(modelValue, () => {
      innerCost.value = formatCost(modelValue.value);
    })

    function formatCost(value: any) {
      if (value === null) {
        return ''
      } else {
        return formatter.format(toNumber(value))
      }
    }

    function toNumber(_str : any) {
      if (_str === '') return null;

      const str = String(_str)
        .replaceAll(/[^0-9.,]+/g, '')
        .replaceAll(',', '.')
        .replace(/(?<=\..*)\./g, '');

      const res = Number(Math.floor(Number(str) * 100) / 100);
      return isNaN(res) ? null : res;
    }

    function onInputCost(value : any) {
      if (value === '') {
        emit('update:modelValue', null);
      } else {
        // оставить - чтобы при вводе того, чего не надо, инпут обновлялся
        const num = toNumber(value);
        const newInnerCost = formatCost(num);
        if (newInnerCost !== value && innerCost.value === newInnerCost && (!value.length || !['.', ','].includes(value[value.length - 1])))
          innerCost.value = '';
        innerCost.value = newInnerCost;
        emit('update:modelValue', num);
      }
    }

    function checkLimit(num: number | null) {
      if (!num) return null;
      if (props.min && num < props.min) {
        innerCost.value = formatCost(props.min);
        return props.min;
      } else if (props.max && props.max < num) {
        innerCost.value = formatCost(props.max);
        return props.max;
      } else {
        return num;
      }
    }

    const costInput = ref();

    function costFocus() {
      costInput.value.focus();
    }

    return {
      costInput,
      innerCost,
      costFocus,
      onInputCost,
      toNumber,
      checkLimit,
    };
  }
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/elements/baseInput";

.base-input {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  width: auto;
  outline: none;

  input {
    width: 100%;
  }

  input::placeholder {
    color: var(--main-placeholder-color);
  }

  .price_title {
    display: flex;
    align-items: center;
    color: var(--main-placeholder-color);
    height: 100%;
  }
}
</style>
